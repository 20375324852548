export default ({ app, $config }) => {
  if (process.client) {
    app.router.beforeEach((to, from, next) => {
      if (to.path === "/contact-us") {
        localStorage.setItem("VUE_APP_ENV", $config.inSyncAi.env);
        localStorage.setItem(
          "VUE_APP_INSYNC_APP_ID_DEV",
          $config.inSyncAi.idDev
        );
        localStorage.setItem(
          "VUE_APP_INSYNC_APP_ID_STAGE",
          $config.inSyncAi.idStage
        );
        localStorage.setItem(
          "VUE_APP_INSYNC_APP_ID_PROD",
          $config.inSyncAi.idProd
        );

        const script = document.createElement("script");
        script.src = "/tracking/inSyncAi.js";
        script.id = "InSyncAI-script";
        script.type = "text/javascript";
        script.defer = true;
        document.head.appendChild(script);
      }
      next();
    });
  }
};
