import Place from "./Place";

export default class Trip {
  public from!: Place;
  public to!: Place;

  public constructor(init?: Partial<Trip>) {
    Object.assign(this, init);
    if (init) {
      if (init.from) {
        this.from = new Place(init.from);
      }
      if (init.to) {
        this.to = new Place(init.to);
      }
    }
  }

  public isSameLocation(): boolean {
    try {
      if (this.from && this.to) {
        if (
          this.from.formatted_address === this.to.formatted_address ||
          (this.from.originalFormattedAddress &&
            this.to.originalFormattedAddress &&
            this.from.originalFormattedAddress ===
              this.to.originalFormattedAddress)
        ) {
          return true;
        }
        // components breakdown check by country, state and postal code
        const fromComponents = {} as any;
        if (
          this.from.address_components &&
          this.from.address_components.constructor === Array
        ) {
          for (let i = 0; i < this.from.address_components.length; i++) {
            const component = this.from.address_components[i];
            const type = component.types[0];
            switch (type) {
              case "postal_code":
                fromComponents.postalCode = component.long_name;
                break;
              case "country":
                fromComponents.countryCode = component.short_name;
                break;
              case "administrative_area_level_1":
                fromComponents.state = component.short_name;
                break;
              default:
                break;
            }
          }
        }
        const toComponents = {} as any;
        if (
          this.to.address_components &&
          this.to.address_components.constructor == Array
        ) {
          for (let i = 0; i < this.to.address_components.length; i++) {
            const component = this.to.address_components[i];
            const type = component.types[0];
            switch (type) {
              case "postal_code":
                toComponents.postalCode = component.long_name;
                break;
              case "country":
                toComponents.countryCode = component.short_name;
                break;
              case "administrative_area_level_1":
                toComponents.state = component.short_name;
                break;
              default:
                break;
            }
          }
        }
        if (
          fromComponents.postalCode === toComponents.postalCode &&
          fromComponents.countryCode === toComponents.countryCode &&
          fromComponents.state == toComponents.state
        ) {
          return true;
        }
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  public updateFormattedAddressLabels() {
    try {
      if (this.from) this.from.formatLabel(this.from, false);
      if (this.to) this.to.formatLabel(this.to, false);
    } catch (e) {}
  }
}
