import { GetterTree, ActionTree, MutationTree } from "vuex";
import {
  Category,
  Country,
  State,
  Insurance,
  Preset,
  Purpose,
  PickupOption,
} from "~/entities/Response/FormData";
import { RootState } from "~/store/index";

export const state = () => ({
  packageCategories: null as Category[] | null,
  packagePresets: null as Preset[] | null,
  packagePurpose: null as Purpose[] | null,
  insuranceOptions: null as Insurance[] | null,
  allowedCountries: null as Country[] | null,
  pickupOptions: null as PickupOption[] | null,
  allowedStates: null as State[] | null,
});

export type FormDataState = ReturnType<typeof state>;

export const getters: GetterTree<FormDataState, RootState> = {
  packageCategories: (state) => state.packageCategories,
  packagePresets: (state) => state.packagePresets,
  packagePurpose: (state) => state.packagePurpose,
  insuranceOptions: (state) => state.insuranceOptions,
  allowedCountries: (state) => state.allowedCountries,
  pickupOptions: (state) => state.pickupOptions,
  allowedStates: (state) => state.allowedStates,
};

export const mutations: MutationTree<FormDataState> = {
  setPackageCategories(state, categories: Category[]) {
    state.packageCategories = categories;
  },
  setPackagePresets(state, presets: Preset[]) {
    state.packagePresets = presets;
  },
  setPackagePurpose(state, purpose: Purpose[]) {
    state.packagePurpose = purpose;
  },
  setInsuranceOptions(state, insurance: Insurance[]) {
    state.insuranceOptions = insurance;
  },
  setAllowedCountries(state, countries: Country[]) {
    state.allowedCountries = countries;
  },
  setPickupOptions(state, pickup: PickupOption[]) {
    state.pickupOptions = pickup;
  },
  setAllowedStates(state, states: State[]) {
    state.allowedStates = states;
  },
  flush: (state, scope: string = "all") => {
    if (scope === "all") {
      state.packageCategories = [];
      state.packagePresets = [];
      state.packagePurpose = [];
      state.insuranceOptions = [];
      state.allowedCountries = [];
      state.pickupOptions = [];
      state.allowedStates = [];
    }
  },
};

export const actions: ActionTree<FormDataState, RootState> = {
  //global ship data
  async fetchPackageCategories({ commit, getters }) {
    try {
      if (!this.getters["formData/packageCategories"]?.length) {
        const response = await this.$axios.get(
          `/api/FormData/package-categories`
        );
        if (response.data) commit("setPackageCategories", response.data);
      }
      return this.getters["formData/packageCategories"];
    } catch (e) {
      return this.getters["formData/packageCategories"];
    }
  },
  async fetchPackagePresets({ commit, getters }) {
    try {
      if (!this.getters["formData/packagePresets"]?.length) {
        const response = await this.$axios.get(`/api/FormData/package-presets`);
        if (response.data) commit("setPackagePresets", response.data);
      }
      return this.getters["formData/packagePresets"];
    } catch (e) {
      return this.getters["formData/packagePresets"];
    }
  },
  async fetchPackagePurpose({ commit, getters }) {
    try {
      if (!this.getters["formData/packagePurpose"]?.length) {
        const response = await this.$axios.get(`/api/FormData/package-purpose`);
        if (response.data) commit("setPackagePurpose", response.data);
      }
      return this.getters["formData/packagePurpose"];
    } catch (e) {
      return this.getters["formData/packagePurpose"];
    }
  },
  async fetchInsuranceOptions({ commit, getters }) {
    try {
      if (!this.getters["formData/insuranceOptions"]?.length) {
        const response = await this.$axios.get(
          `/api/FormData/insurance-options`
        );
        if (response.data) commit("setInsuranceOptions", response.data);
      }
      return this.getters["formData/insuranceOptions"];
    } catch (e) {
      return this.getters["formData/insuranceOptions"];
    }
  },
  async fetchAllowedCountries({ commit, getters }) {
    try {
      if (!this.getters["formData/allowedCountries"]?.length) {
        const response = await this.$axios.get(`/api/FormData/countries`);
        if (response.data) commit("setAllowedCountries", response.data);
      }
      return this.getters["formData/allowedCountries"];
    } catch (e) {
      return this.getters["formData/allowedCountries"];
    }
  },
  async fetchPickupOptions({ commit, getters }) {
    try {
      if (!this.getters["formData/pickupOptions"]?.length) {
        const response = await this.$axios.get(`/api/FormData/pickup-options`);
        if (response.data) commit("setPickupOptions", response.data);
      }
      return this.getters["formData/pickupOptions"];
    } catch (e) {
      return this.getters["formData/pickupOptions"];
    }
  },
  async fetchAllowedStates({ commit, getters }) {
    try {
      if (!this.getters["formData/allowedStates"]?.length) {
        const response = await this.$axios.get(`/api/FormData/states`);
        if (response.data) commit("setAllowedStates", response.data);
      }
      return this.getters["formData/allowedStates"];
    } catch (e) {
      return this.getters["formData/allowedStates"];
    }
  },
};
