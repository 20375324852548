
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
  data() {
    return {
      version: "",
    };
  },
  computed: {
    // Vuex getters
    ...mapGetters({
      numServed: "numServed",
    }),
  },
  created() {
    this.getNumServed();
  },
  mounted() {
    Vue.set(this, "version", this.$config.version);
  },
  methods: {
    // Vuex actions
    ...mapActions({
      getNumServed: "getNumServed",
    }),
  },
  fetchOnServer: false,
});
