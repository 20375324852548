import Box from "~/entities/Box";
import Trip from "~/entities/Trip";

export default class Search {
  public created!: number;
  public updated!: number;
  public id!: number; //internal use id, used to identify the search in the session stack
  public ownerId?: string; //potential use, this should be the id of the user if session has a valid user property
  public savedSearchId?: number; //used to identify the search in the DB and api calls
  public savedSearchCode?: string; //also used to identify the search in the DB and api calls
  public step!: number;
  public prevStep!: number;
  public restored!: boolean;
  public active!: boolean;

  //search info
  public trips!: Array<Trip>;
  public details!: any[];
  public boxes!: Array<Box>;
  public descriptions!: any[];
  public units!: number;
  public purpose!: string;
  public rates!: any[];
  public selectedRateSpeed!: [];
  public selectedRateSpeedIndex!: [0];
  public premiumProtection!: {};
  public subTotal!: number;

  public constructor(
    init?: Partial<Search> | String | number,
    restored: boolean = false
  ) {
    if (init && init instanceof Search) {
      Object.assign(this, init);
    } else if (init instanceof String) {
      //JSON parse
      let jsonData = JSON.parse(init as string);
      this.setSearchData(jsonData);
    } else {
      this.created = new Date().getTime();
      this.updated = this.created;
      if (init && !isNaN(init as number)) {
        this.id = init as number;
      } else {
        this.id = 0;
      }
    }
    this.restored = restored; // check why this is not working
    if (restored) {
      this.active = false;
    }
  }

  public setSearchData(value: any, firstRestore: boolean = false): boolean {
    let valid = true;
    if (value.created) {
      this.created = value.created;
    } else {
      this.created = new Date().getTime();
    }
    if (value.updated) {
      this.updated = value.updated;
    } else {
      this.updated = this.created;
    }
    if (value.id) {
      this.id = value.id;
    } else {
      this.id = 0;
    }
    if (value.ownerId) {
      this.ownerId = value.ownerId;
    }
    if (value.savedSearchId) {
      this.savedSearchId = value.savedSearchId;
    }
    if (value.savedSearchCode) {
      this.savedSearchCode = value.savedSearchCode;
    }
    if (value.step) {
      this.step = value.step;
    }
    if (value.prevStep) {
      this.prevStep = value.prevStep;
    }
    if (firstRestore) {
      this.restored = true;
      this.active = false;
    }
    if (value.active && !firstRestore) {
      this.active = value.active;
    }
    if (value.trips) {
      if (value.trips.length) {
        this.trips = value.trips;
      }
    }
    if (value.details) {
      this.details = value.details;
    }
    if (value.boxes) {
      if (value.boxes.length) {
        this.boxes = value.boxes;
      }
    }
    if (value.descriptions) {
      this.descriptions = value.descriptions;
    }
    if (value.units) {
      this.units = value.units;
    }
    if (value.purpose) {
      this.purpose = value.purpose;
    }
    if (value.rates) {
      if (value.rates.length) {
        this.rates = value.rates;
      }
    }
    if (value.selectedRateSpeed) {
      this.selectedRateSpeed = value.selectedRateSpeed;
    }
    if (value.selectedRateSpeedIndex) {
      this.selectedRateSpeedIndex = value.selectedRateSpeedIndex;
    }
    if (value.premiumProtection) {
      this.premiumProtection = value.premiumProtection;
    }
    if (value.subTotal) {
      this.subTotal = value.subTotal;
    }
    return valid;
  }

  public updateSearchData(value: any): boolean {
    let valid = true;
    if (value.updated) {
      this.updated = value.updated;
    } else {
      this.updated = new Date().getTime();
    }
    if (value.id) {
      this.id = value.id;
    }
    if (value.ownerId) {
      this.ownerId = value.ownerId;
    }
    if (value.savedSearchId) {
      this.savedSearchId = value.savedSearchId;
    }
    if (value.savedSearchCode) {
      this.savedSearchCode = value.savedSearchCode;
    }
    if (value.step && value.step > 1) {
      if (this.step) {
        this.step = value.step > this.step ? value.step : this.step;
      } else {
        this.step = value.step;
      }
    }
    if (value.prevStep) {
      this.prevStep = value.prevStep;
    }
    if (value.active) {
      this.active = value.active;
    }
    if (value.trips) {
      if (value.trips.length) {
        for (let step = 0; step < value.trips.length; step++) {
          if (!value.trips[step].from || !value.trips[step].to) {
            valid = false;
          }
        }
        if (valid) this.trips = value.trips;
      } else {
        valid = false;
      }
    }
    if (value.details) {
      this.details = value.details;
    }
    if (value.boxes) {
      if (value.boxes.length) {
        this.boxes = value.boxes;
      } else {
        valid = false;
      }
    }
    if (value.descriptions) {
      this.descriptions = value.descriptions;
    }
    if (value.units) {
      this.units = value.units;
    }
    if (value.purpose) {
      this.purpose = value.purpose;
    }
    if (value.rates) {
      if (value.rates.length) {
        this.rates = value.rates;
      } else {
        valid = false;
      }
    }
    if (value.selectedRateSpeed) {
      this.selectedRateSpeed = value.selectedRateSpeed;
    }
    if (value.selectedRateSpeedIndex) {
      this.selectedRateSpeedIndex = value.selectedRateSpeedIndex;
    }
    if (value.premiumProtection) {
      this.premiumProtection = value.premiumProtection;
    }
    if (value.subTotal) {
      this.subTotal = value.subTotal;
    }
    return valid;
  }

  public setProperty(property: string, value: any): void {
    if (this[property]) {
      this[property] = value;
    }
  }
}
