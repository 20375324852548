var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.notice)?_c('div',{staticClass:"notice relative",class:[
    _vm.showing ? 'block' : 'hidden',
    _vm.notice.type == 'alert-warning'
      ? 'warning'
      : _vm.notice.type == 'alert-danger'
      ? 'danger'
      : _vm.notice.type == 'alert-success'
      ? 'success'
      : 'info',
    _vm.center ? 'text-center' : '',
    _vm.isHeader ? '' : 'mb-5',
  ],attrs:{"id":_vm.page}},[(_vm.notice.dismissable)?_c('button',{staticClass:"h-3 float-right ml-0.5 hover:opacity-75",attrs:{"aria-label":"close","type":"button"},on:{"click":function($event){return _vm.hide()}}},[_c('fa',{staticClass:"h-3",attrs:{"icon":['fas', 'xmark']}})],1):_vm._e(),_vm._v(" "),(_vm.notice.expletive)?_c('strong',{domProps:{"innerHTML":_vm._s(_vm.notice.expletive)}}):_vm._e(),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.filterMessage())}})]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }