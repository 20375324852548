var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitLogin.apply(null, arguments)}}},[_c('h4',{staticClass:"uppercase font-extrabold text-lg text-center pb-5"},[_vm._v("Login")]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.actionNotice),expression:"actionNotice"}],staticClass:"items-center w-full"},[_c('Notice',{attrs:{"notice":_vm.actionNotice}})],1),_vm._v(" "),_c('div',{staticClass:"input-group mb-2",class:{
      touched:
        (_vm.submitted && _vm.email && !_vm.isValidEmail(_vm.email)) ||
        _vm.successLogin === false ||
        (_vm.submitted && !_vm.email),
    },attrs:{"maxlength":"60"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],ref:"authEmail",staticClass:"w-full text-base sm:text-sm",attrs:{"type":"email","maxlength":"50"},domProps:{"value":(_vm.email)},on:{"input":[function($event){if($event.target.composing)return;_vm.email=$event.target.value},_vm.resetVal]}}),_vm._v(" "),_c('label',[_vm._v("Email")])]),_vm._v(" "),_c('div',{staticClass:"input-group mb-2",class:{ touched: _vm.successLogin === false || (_vm.submitted && !_vm.password) }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"w-full text-base sm:text-sm",attrs:{"type":"password","maxlength":"60"},domProps:{"value":(_vm.password)},on:{"input":[function($event){if($event.target.composing)return;_vm.password=$event.target.value},_vm.resetVal]}}),_vm._v(" "),_c('label',[_vm._v("Password")])]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"text-center",on:{"click":function($event){return _vm.closeMenu()}}},[_c('nuxtLink',{staticClass:"text-sm font-normal",attrs:{"target":"_self","to":"/forgotPassword"}},[_vm._v("Forgot Password?")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mb-2"},[_c('button',{staticClass:"uppercase w-full bg-brand-500 hover:bg-brand-400 text-white rounded font-semibold text-sm p-3 border-2 border-brand-500 hover:border-brand-400",attrs:{"aria-label":"log in","type":"submit"}},[_vm._v("\n      Log In\n    ")])])
}]

export { render, staticRenderFns }