
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      numServed: "numServed",
    }),
  },
  mounted() {
    this.getNumServed();
  },
  beforeDestroy() {
    if (window?.fcWidget?.isOpen()) {
      window.fcWidget.close();
    }
  },
  methods: {
    ...mapActions({
      getNumServed: "getNumServed",
    }),
  },
  fetchOnServer: false,
});
