export function formatDate(
  date: any,
  daysDelta = 0,
  raw = false,
  holidays: any[],
  rates: any[]
): Date {
  if (!date) {
    date = new Date();
  } else if (!date || typeof date === "string" || date instanceof String) {
    date = new Date(date as string);
  }
  if (daysDelta) {
    let increment, skips, auxDate;
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    const fixDate = date.toString().includes("+")
      ? date.getTime() + userTimezoneOffset
      : date.getTime() - userTimezoneOffset;
    for (
      increment = 0, skips = 0, auxDate = new Date(fixDate);
      increment <= daysDelta && skips < 31;
      auxDate.setDate(auxDate.getDate() + 1)
    ) {
      if (getDisabledDates(auxDate, rates, holidays)) {
        skips++;
      } else {
        increment++;
      }
      if (increment > daysDelta) break;
    }
    date = new Date(auxDate.getTime());
  }
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  const fixDate = date.toString().includes("+")
    ? date.getTime() - userTimezoneOffset
    : date.getTime() + userTimezoneOffset;
  const dayOfWeek = new Date(fixDate).getDay();
  if (dayOfWeek === 6 || dayOfWeek === 0) {
    date.setDate(date.getDate() + (dayOfWeek === 6 ? 3 : 2));
  }
  if (raw) {
    return date;
  }
  return date.toLocaleDateString("en-us", {
    year: "numeric",
    weekday: "short",
    month: "short",
    day: "numeric",
  });
}

export function isADayOff(date: Date, holidays: any[]): boolean {
  if (holidays && holidays.length > 0) {
    for (let index = 0; index < holidays.length; index++) {
      const holiday = new Date(holidays[index]);
      const parseHoliday = holiday.toISOString();
      const parseDate = date.toISOString();
      if (
        new Date(parseDate.split("T")[0]).getTime() ===
        new Date(parseHoliday.split("T")[0]).getTime()
      ) {
        return true;
      }
    }
  }
  return false;
}

export function getDisabledDates(
  calendarDate: Date,
  rates: any[],
  holidays,
  shipDates?: string[],
  selectedDate: Date[] = [],
  index: number = -1
): boolean {
  const currentYear = new Date().getFullYear();
  if (isADayOff(calendarDate, holidays)) {
    // Disable if is a Holiday
    return true;
  }
  // TODO: Check if this should be 12months or 1 year
  if (calendarDate.getFullYear() > currentYear + 1) {
    // Disable if a calendarDate is a year in the future to improve performance
    return true;
  }
  if (!(calendarDate.getDay() % 6)) {
    // Disable if is a weekend
    return true;
  }
  if (isBeforeOrToday(calendarDate, false)) {
    // Disable days before Today
    return true;
  }
  if (rates[0][0]?.pickupFee > 0 && isBeforeOrToday(calendarDate, true)) {
    // Disable if is a pickup and the day is today or before
    return true;
  }
  if (shipDates?.length && index !== -1) {
    const previousSelectedDate = selectedDate?.[0] || null;
    for (let i = 0; i < shipDates.length; i++) {
      const shipDate = new Date(shipDates[i] || "");
      const previousShipDate = new Date(shipDates[i - 1] || "");

      if (shipDate?.getTime() === calendarDate?.getTime() && index !== i) {
        // Disable if a date is already selected on another Stage and the Stage is not the same as the index
        return true;
      }

      if (
        i > 0 &&
        index > 0 &&
        calendarDate < previousShipDate &&
        previousShipDate.getTime() !== previousSelectedDate?.getTime()
      ) {
        // Disable if a date is before the previous Ship Date
        return true;
      }
    }
  }
  return false;
}

export function isBeforeOrToday(
  dateInput: Date,
  isEqual: boolean = false
): boolean {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set the time to 00:00:00.000
  dateInput.setHours(0, 0, 0, 0); // Set the time to 00:00:00.000

  return isEqual ? dateInput <= today : dateInput < today;
}
