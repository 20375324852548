const middleware = {}

middleware['analytics'] = require('../middleware/analytics.js')
middleware['analytics'] = middleware['analytics'].default || middleware['analytics']

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['checkVersion'] = require('../middleware/checkVersion.js')
middleware['checkVersion'] = middleware['checkVersion'].default || middleware['checkVersion']

export default middleware
