
import Vue from "vue";
import { mapGetters } from "vuex";
import NavMenu from "~/components/menu/NavMenu.vue";
import UserMenu from "~/components/menu/UserMenu.vue";
import User from "~/entities/User";

export default Vue.extend({
  name: "Navbar",
  components: { NavMenu, UserMenu },
  data() {
    return {
      activeMenu: 0,
      user: {
        type: [User, undefined],
        default: undefined,
      },
    };
  },
  computed: {
    // Vuex getters
    ...mapGetters({
      session: "user/session",
    }),
  },
  methods: {
    onToggleMenu(activeMenu: number) {
      this.activeMenu = activeMenu;
    },
    close() {
      this.activeMenu = 0;
    },
  },
});
