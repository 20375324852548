import Search from "~/entities/Search";

export default class User {
  public id!: string;
  public firstName!: string;
  public lastName!: string;
  public companyName!: string;
  public email!: string;
  public phoneNumber!: string;
  public roles!: Array<string>;
  public settings!: Settings;
  public exp!: string;

  public constructor(init?: Partial<User>) {
    //this will be passed a json string or something from the localstorage. will have to initiate the search objects
    if (init) {
      if (init instanceof User) {
        Object.assign(this, init);
      } else {
        this.id = init.id ? init.id : "";
        this.firstName = init.firstName ? init.firstName : "";
        this.lastName = init.lastName ? init.lastName : "";
        this.companyName = init.companyName ? init.companyName : "";
        this.email = init.email ? init.email : "";
        this.phoneNumber = init.phoneNumber ? init.phoneNumber : "";
        this.settings = new Settings(init);
        this.exp = init.exp ? init.exp : "";
        if (init && init.roles?.constructor == Array && init.roles.length) {
          this.roles = init.roles;
        } else {
          this.roles = ["Guest"];
        }
      }
    } else {
      this.id = "";
      this.email = "";
    }
  }

  public setID(id: string): void {
    this.id = id;
  }

  public getID(): string {
    return this.id;
  }
}

export class Settings {
  public savePackages!: boolean;
  public saveAddresses!: boolean;
  public savePaymentMethods!: boolean;
  public mailingListOptIn!: boolean;
  public newsLetterOptIn!: boolean;

  public constructor(user: any) {
    this.savePackages = user.SavePackages;
    this.saveAddresses = user.SaveAddresses;
    this.savePaymentMethods = user.SavePaymentMethods;
    this.mailingListOptIn = user.MailingListOptIn;
    this.newsLetterOptIn = user.NewsLetterOptIn;
  }
}
