import Vue from 'vue'
import { createImage} from '~image'
import NuxtImg from '~image/components/nuxt-img.vue'
import NuxtPicture from '~image/components/nuxt-picture.vue'

import * as staticRuntime$bab9 from '/opt/atlassian/pipelines/agent/build/node_modules/@nuxt/image/dist/runtime/providers/static.js'
import * as cloudinaryRuntime$bcaf from '/opt/atlassian/pipelines/agent/build/node_modules/@nuxt/image/dist/runtime/providers/cloudinary.js'
import * as gcloudRuntime$8cfa from '/opt/atlassian/pipelines/agent/build/providers/gcloud/index.js'

const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "hero": {
      "modifiers": {
        "width": 2000,
        "height": 600,
        "quality": 60,
        "fit": "cover"
      }
    },
    "webp_medium": {
      "modifiers": {
        "format": "webp",
        "quality": 60,
        "fit": "cover"
      }
    }
  },
  "provider": "gcloud",
  "domains": [],
  "alias": {}
}

imageOptions.providers = {
  ['static']: { provider: staticRuntime$bab9, defaults: {} },
  ['cloudinary']: { provider: cloudinaryRuntime$bcaf, defaults: {"baseURL":"https://res.cloudinary.com/dylqiddds/image/upload/bikeflights/"} },
  ['gcloud']: { provider: gcloudRuntime$8cfa, defaults: {"baseURL":"https://res.cloudinary.com/dylqiddds/image/upload/bikeflights/"} }
}

Vue.component(NuxtImg.name, NuxtImg)
Vue.component(NuxtPicture.name, NuxtPicture)
Vue.component('NImg', NuxtImg)
Vue.component('NPicture', NuxtPicture)

export default function (nuxtContext, inject) {
  const $img = createImage(imageOptions, nuxtContext)

  if (process.static && process.server) {
    nuxtContext.beforeNuxtRender(({ nuxtState }) => {
      const ssrData = nuxtState.data[0] || {}
      ssrData._img = nuxtState._img || {}
    })
  }

  inject('img', $img)
}
