import { Details } from "./Details";
import * as Sentry from "@sentry/vue";
import Box from "~/entities/Box";
import Package from "~/entities/Package";
import Search from "~/entities/Search";
import Stage from "~/entities/Stage";
import Trip from "~/entities/Trip";

export interface ForLocationI {
  city?: string;
  stateCode?: string;
  postalCode?: string;
  countryCode?: string;
  provider?: string;
}

export class AddressRequest {
  address1: string;
  address2: string;
  postalCode: string;
  city: string;
  state: string;
  company: string;
  country: string;
  triggerType: number | null;
  public constructor(init?: Partial<AddressRequest>) {
    Object.assign(this, init);
    this.address1 = "";
    this.address2 = "";
    this.postalCode = "";
    this.city = "";
    this.state = "";
    this.country = "";
    this.company = "";
    this.triggerType = null;
  }
}

export default class StoreHelper {
  public static shipDateMachine: object = {
    0: "stage1ShipDate",
    1: "stage2ShipDate",
    2: "stage3ShipDate",
    3: "stage4ShipDate",
    4: "stage5ShipDate",
  };

  public static deliverDateMachine: object = {
    0: "stage1DeliverDate",
    1: "stage2DeliverDate",
    2: "stage3DeliverDate",
    3: "stage4DeliverDate",
    4: "stage5DeliverDate",
  };

  public static formatDate(date: string) {
    const auxDate = new Date(date + "T00:00:01.001Z");
    auxDate.setDate(auxDate.getDate());
    return auxDate.toISOString();
  }

  public static getDateMachine(index: number, currentEvent: any): string {
    if (currentEvent[this.deliverDateMachine[index]]) {
      return currentEvent[this.deliverDateMachine[index]];
    } else if (currentEvent[this.shipDateMachine[index]]) {
      return currentEvent[this.shipDateMachine[index]];
    }
    return "";
  }

  public static getDateMachineType(index: number, currentEvent: any): string {
    if (currentEvent[this.deliverDateMachine[index]]) {
      return "D";
    } else if (currentEvent[this.shipDateMachine[index]]) {
      return "S";
    }
    return "";
  }

  public static getRequestRatesBodyObject(getters: any, rootGetters: any) {
    const request: { shipMode: number; stages: Stage[]; packages: any } =
      {} as any;
    request.shipMode =
      getters.shipMode && getters.shipMode.id
        ? getters.shipMode.id
        : (1 as number);
    request.stages = [] as Stage[];
    // iterate trips and fill trips
    const trips = getters.trips;
    const boxes = getters.boxes;
    const details = getters.details;
    const currentUnit = getters.units;
    const currentEvent = rootGetters["eventShipping/currentEvent"];
    // usa trips para el calculo de rates
    // pero como hace entonces cuando edita la direccion en step 3?
    if (trips.constructor === Array && trips.length) {
      for (let step = 0; step < trips.length; step++) {
        const stage = {} as Stage;
        const trip: any = trips[step];
        const auxDate = new Date();
        auxDate.setDate(auxDate.getDate());
        stage.shipDate = currentEvent[this.shipDateMachine[step]]
          ? this.formatDate(currentEvent[this.shipDateMachine[step]])
          : auxDate.toISOString();
        stage.deliverDate = currentEvent[this.deliverDateMachine[step]]
          ? this.formatDate(currentEvent[this.deliverDateMachine[step]])
          : auxDate.toISOString();
        stage.pickupOption =
          getters.pickupOption && getters.pickupOption[step]
            ? getters.pickupOption[step]
            : {
                entryId: 0,
                tag: "string",
                code: 0,
                display: "string",
                value: 0,
                cost: 0,
              };
        stage.purposeOption = getters.purpose
          ? getters.purpose
          : {
              entryId: 0,
              tag: "string",
              code: 0,
              display: "string",
              value: 0,
              cost: 0,
            };
        stage.stage = step;
        if (
          currentEvent?.[this.shipDateMachine[step]] ||
          currentEvent?.[this.deliverDateMachine[step]]
        ) {
          stage.actionDate = StoreHelper.getDateMachine(step, currentEvent);
          stage.dateType = StoreHelper.getDateMachineType(step, currentEvent);
        }
        const fromComponents = this.mapAddressComponents(
          trip.from.address_components,
          trip.from.formatted_address
        ) as any;
        stage.from = {
          city: fromComponents.city ? fromComponents.city : ("" as string),
          state: fromComponents.state
            ? fromComponents.state?.stateprovcode
              ? fromComponents.state.stateprovcode
              : fromComponents.state
            : ("" as string),
          stateName: fromComponents.stateName
            ? fromComponents.stateName
            : fromComponents.state?.label
            ? fromComponents.state.label
            : ("" as string),
          county: fromComponents.county
            ? fromComponents.county
            : ("" as string),
          postalCode: fromComponents.postalCode
            ? fromComponents.postalCode
            : ("" as string),
          countryCode: fromComponents.countryCode
            ? fromComponents.countryCode
            : fromComponents.state?.country
            ? fromComponents.state.country
            : ("" as string),
          passedAVS: !!details?.[step]?.from?.passedAvs,
          isResidential: !!details?.[step]?.from?.isResidential,
          location: {
            lat: (typeof trip.from.geometry.location.lat === "function"
              ? trip.from.geometry.location.lat()
              : trip.from.geometry.location.lat) as Number, //
            lng: (typeof trip.from.geometry.location.lng === "function"
              ? trip.from.geometry.location.lng()
              : trip.from.geometry.location.lng) as Number, //
          },
        };
        const toComponents = this.mapAddressComponents(
          trip.to.address_components,
          trip.to.formatted_address
        ) as any;
        stage.to = {
          city: toComponents.city ? toComponents.city : ("" as string),
          state: toComponents.state
            ? toComponents.state?.stateprovcode
              ? toComponents.state.stateprovcode
              : toComponents.state
            : ("" as string),
          stateName: toComponents.stateName
            ? toComponents.stateName
            : toComponents.state?.label
            ? toComponents.state.label
            : ("" as string),
          county: toComponents.county ? toComponents.county : ("" as string),
          postalCode: toComponents.postalCode
            ? toComponents.postalCode
            : ("" as string),
          countryCode: toComponents.countryCode
            ? toComponents.countryCode
            : toComponents.state?.country
            ? toComponents.state.country
            : ("" as string),
          passedAVS: !!details?.[step]?.to?.passedAvs,
          isResidential: !!details?.[step]?.to?.isResidential,
          location: {
            lat: (typeof trip.to.geometry.location.lat === "function"
              ? trip.to.geometry.location.lat()
              : trip.to.geometry.location.lat) as Number, //
            lng: (typeof trip.to.geometry.location.lng === "function"
              ? trip.to.geometry.location.lng()
              : trip.to.geometry.location.lng) as Number, //
          },
        };
        request.stages.push(stage);
      }
    }

    request.packages = [];
    // iterate boxes and fill packages
    if (boxes.constructor === Array && boxes.length) {
      for (let step = 0; step < boxes.length; step++) {
        const _package = {} as Package;
        const box: any = boxes[step];
        _package.name = box ? box.preset : "";
        _package.length = box.length;
        _package.height = box.height;
        _package.width = box.width;
        _package.weight = box.weight;
        _package.linearUnit =
          currentUnit && currentUnit.length === "cm" ? "CM" : "IN";
        _package.weightUnit =
          currentUnit && currentUnit.weight === "kg" ? "KG" : "LB";
        _package.value =
          typeof box.marketValue === "string"
            ? Number(box.marketValue)
            : box.marketValue;
        _package.coverage = box.premiumProtection.coverage;
        _package.protectionFee = box.premiumProtection.cost;
        _package.contentOption = box.contents
          ? box.contents
          : {
              entryId: box.contents.id,
              tag: String(box.contents.code) as string,
              code: box.contents.code as number,
              display: box.contents.label,
              value: 0,
              cost: 0,
            };
        request.packages.push(_package);
      }
    }
    return request;
  }

  public static mapAddressComponents(
    addressComponents,
    formattedAddress = undefined
  ) {
    const components = {} as any;
    try {
      if (addressComponents.constructor === Array && addressComponents.length) {
        for (let i = 0; i < addressComponents.length; i++) {
          const component = addressComponents[i];
          const type = component.types[0];
          switch (type) {
            case "street_number":
              components.streetNumber = component.long_name;
              break;
            case "route":
              components.streetAddress =
                (components.streetNumber &&
                !component.long_name.includes(components.streetNumber)
                  ? components.streetNumber
                  : "") +
                " " +
                component.long_name;
              components.streetAddress = components.streetAddress.trim();
              if (!components.address1)
                components.address1 = component.short_name;
              break;
            case "postal_code":
              components.postalCode = component.long_name;
              break;
            case "neighborhood":
              components.neighborhood = component.long_name;
              break;
            case "locality":
              components.city = component.long_name;
              break;
            case "postal_town":{
              if (!components.city) {
                components.city = component.long_name;
              }
              break;
            }
            case "country":
              components.countryCode = component.short_name;
              components.country = component.long_name;
              break;
            case "administrative_area_level_2":
              components.county = component.long_name;
              break;
            case "administrative_area_level_1":
              components.state = component.short_name;
              components.stateName = component.long_name;
              break;
          }
        }
      }
      if (
        components.neighborhood &&
        formattedAddress &&
        this.didUserEnterThis(formattedAddress, components.neighborhood)
      ) {
        components.city = components.neighborhood;
      }

      return components;
    } catch (e) {
      return components;
    }
  }

  public static didUserEnterThis(originalAddress, partToCheck) {
    if (!partToCheck) {
      return false;
    }
    partToCheck = partToCheck.trim();
    originalAddress = originalAddress.trim();
    if (originalAddress.includes(partToCheck)) {
      return true;
    }
    return false;
  }

  public static reverseMapAddressComponents(addressComponents, detail) {
    if (addressComponents.constructor === Array && addressComponents.length) {
      for (let i = 0; i < addressComponents.length; i++) {
        const component = addressComponents[i];
        const type = component.types[0];
        switch (type) {
          case "street_number":
            addressComponents[i].long_name = detail.streetNumber;
            break;
          case "route":
            addressComponents[i].long_name =
              (detail.streetNumber &&
              !detail.streetAddress.includes(detail.streetNumber)
                ? detail.streetNumber
                : "") +
              " " +
              detail.streetAddress;
            addressComponents[i].short_name = detail.streetAddress;
            if (!addressComponents[i].long_name)
              addressComponents[i].long_name = detail.address1;
            if (!addressComponents[i].short_name)
              addressComponents[i].short_name = detail.address1;
            break;
          case "postal_code":
            addressComponents[i].long_name = detail.postalCode;
            addressComponents[i].short_name = detail.postalCode;
            break;
          case "locality":
            addressComponents[i].long_name = detail.city;
            addressComponents[i].short_name = detail.city;
            break;
          case "country":
            addressComponents[i].long_name = detail.country.name;
            addressComponents[i].short_name = detail.country.code;
            break;
          case "administrative_area_level_2":
            addressComponents[i].long_name = detail.county;
            addressComponents[i].short_name = detail.county;
            break;
          case "administrative_area_level_1":
            addressComponents[i].long_name = detail.state?.stateprov
              ? detail.state.stateprov
              : detail.state;
            addressComponents[i].short_name = detail.state?.stateprovcode
              ? detail.state?.stateprovcode
              : detail.state;
            break;
        }
      }
    }
  }

  public static validateUPSRates(ratesObject) {
    /* delete this return when getRates works again */
    try {
      if (ratesObject.constructor === Array && ratesObject.length) {
        for (let j = 0; j < ratesObject.length; j++) {
          if (
            !ratesObject[j][0] ||
            ratesObject[j][0].rates === null ||
            ratesObject[j][0].rates === undefined ||
            (ratesObject[j][0].rates && ratesObject[j][0].rates.length < 1)
          ) {
            return false;
          } else {
            for (let i = 0; i < ratesObject[j][0].rates.length; i++) {
              const rate = ratesObject[j][0].rates[i];
              if (rate) {
                if (
                  rate.rate >= 0 &&
                  rate.transitDays >= 0 &&
                  rate.upsTransitDays >= 0
                ) {
                  continue;
                } else {
                  return false;
                }
              } else {
                return false;
              }
            }
          }
        }
        return true;
      }
      return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  public static filterRatesResponse(ratesResponse) {
    try {
      for (let index = 0; index < ratesResponse[0].length; index++) {
        // sort by price cheapest to more expensive
        const cheapSorted = ratesResponse[0][index].rates.sort((a, b) => {
          return a.rate < b.rate ? -1 : a.rate > b.rate ? 1 : 0; // FF expect the return to be either -1, 0, or 1, any different value will cause unexpected issues.
        });

        let maxSpeed = 9999;
        let fastest = 0;

        if (cheapSorted[0]) {
          cheapSorted[0].speed = "Fast";
        }

        // if next cheapest option has a transit time more than the cheapest service, do not show the next cheaper
        const speedSorted = cheapSorted.filter((b, i) => {
          if (b.transitDays < maxSpeed) {
            maxSpeed = b.transitDays;
            fastest = i;
          } else if (b.transitDays === maxSpeed) {
            fastest = 0;
          }

          if (i === 0) {
            return true;
          }

          const a = cheapSorted[0];
          const isSlower = a.transitDays >= b.transitDays;

          if (a.transitDays === b.transitDays) {
            b.speed = "Fast";
          } else if (a.transitDays > b.transitDays) {
            b.speed = "Faster";
          }

          return isSlower;
        });

        if (fastest && cheapSorted[fastest]) {
          cheapSorted[fastest].speed = "Fastest";
        }

        if (speedSorted.length === 0) {
          return ratesResponse;
        }

        ratesResponse[0][index].rates = speedSorted;
      }

      return ratesResponse;
    } catch (e) {
      console.log(e);
      return ratesResponse;
    }
  }

  public static updateTripDetails(trips: Trip[], details) {
    // getFormattedAddress is a function to get the formatted address from the details object
    for (let step = 0; step < trips.length; step++) {
      if (trips[step].from && details && details[step] && details[step].from) {
        this.reverseMapAddressComponents(
          trips[step].from.address_components,
          details[step].from
        );
        trips[step].from.getFormattedAddress(details[step].from);
      }
      if (trips[step].to && details && details[step] && details[step].to) {
        this.reverseMapAddressComponents(
          trips[step].to.address_components,
          details[step].to
        );
        trips[step].to.getFormattedAddress(details[step].to);
      }
      // do the same for the rest of the components
    }
  }

  public static getRequestLandedCostBodyObject(getters: any, index, rateIndex) {
    const request = {} as any;
    request.packages = [] as Stage[];
    request.stage = index;
    request.result = rateIndex;
    // iterate trips and fill trips
    const trips = getters.trips;
    const boxes = getters.boxes;
    const currentUnit = getters.units;
    const purpose = getters.purpose;
    const rates = getters.rates;
    let costOfGoods: number = 0;
    let insuranceCharge = 0;

    try {
      // iterate boxes and fill packages
      if (boxes.constructor === Array && boxes.length) {
        for (let step = 0; step < boxes.length; step++) {
          const _package = {} as any;
          const box: any = boxes[step];
          _package.categoryCode = box.contents?.code as number;
          _package.name = box ? box.preset : "";
          _package.length = box.length;
          _package.height = box.height;
          _package.width = box.width;
          _package.weight = box.weight;
          _package.linearUnit =
            currentUnit && currentUnit.length === "cm" ? "CM" : "IN";
          _package.weightUnit =
            currentUnit && currentUnit.weight === "kg" ? "KG" : "LB";
          _package.value =
            typeof box.marketValue === "string"
              ? Number(box.marketValue)
              : box.marketValue;
          costOfGoods +=
            typeof box.marketValue === "string"
              ? Number(box.marketValue)
              : (box.marketValue as number);
          _package.coverage = box.premiumProtection.coverage;
          _package.protectionFee = box.premiumProtection.cost;
          insuranceCharge += _package.protectionFee as number;
          request.packages.push(_package);
        }
      }

      // fill stage details
      if (trips.constructor === Array && trips.length && trips[index]) {
        const trip: any = trips[index];
        const fromComponents = this.mapAddressComponents(
          trip.from.address_components,
          trip.from.formatted_address
        ) as any;
        const toComponents = this.mapAddressComponents(
          trip.to.address_components,
          trip.to.formatted_address
        ) as any;
        if (
          (toComponents.countryCode &&
            fromComponents.countryCode &&
            fromComponents.countryCode === toComponents.countryCode) ||
          (fromComponents.country &&
            fromComponents.country.code &&
            toComponents.country.code &&
            toComponents.country &&
            fromComponents.country.code === toComponents.country.code)
        ) {
          // in the future, replace this with a better detection of LandedCost req
          return false;
        }
        request.fromCountry = fromComponents.countryCode
          ? fromComponents.countryCode
          : fromComponents.country && fromComponents.country.code
          ? fromComponents.country.code
          : "";
        if (fromComponents.state) {
          request.fromStateProv = String(fromComponents.state as string).trim();
        } else if (fromComponents.stateName) {
          request.fromStateProv = String(
            fromComponents.stateName as string
          ).trim();
        } else {
          request.fromStateProv = "";
        }
        request.toCountry = toComponents.countryCode
          ? toComponents.countryCode
          : toComponents.country && toComponents.country.code
          ? toComponents.country.code
          : "";
        if (toComponents.state) {
          request.toStateProv = String(toComponents.state as string).trim();
        } else if (toComponents.stateName) {
          request.toStateProv = String(toComponents.stateName as string).trim();
        } else {
          request.toStateProv = "";
        }
        request.costOfGoods = costOfGoods;
        request.shippingCharge =
          rates[0] &&
          rates[0][index] &&
          rates[0][index].rates &&
          rates[0][index].rates[rateIndex]
            ? rates[0][index].rates[rateIndex].rate
            : 0;
        request.insuranceCharge = insuranceCharge;
        request.purposeCode = purpose?.code;
      }
      return request;
    } catch (e) {
      console.log(e);
      return request;
    }
  }

  public static getTemporalOrderBodyObject(
    getters: any,
    session: any,
    rootGetters: any,
    commit: any
  ) {
    const shipment = {} as any;
    const auxDate = new Date();
    auxDate.setDate(auxDate.getDate());
    const todayTimezoneOffset = auxDate.getTimezoneOffset() * 60000;
    const fixDate = auxDate.toString().includes("+")
      ? auxDate.getTime() - todayTimezoneOffset
      : auxDate.getTime() + todayTimezoneOffset;
    const ISOStringDate = this.dateToISO(new Date(fixDate));
    shipment.shipMode =
      getters.shipMode && getters.shipMode.id
        ? getters.shipMode.id
        : (1 as number);
    shipment.purpose =
      getters.purpose && getters.purpose.code
        ? getters.purpose.code
        : (0 as number);
    shipment.shipDate = ISOStringDate;
    shipment.stages = [] as any[];
    shipment.packages = [] as any[];
    shipment.session = { profile: {} as any } as any;

    shipment.timezoneData = {
      timezone: new Intl.DateTimeFormat("en-US", { timeZoneName: "short" })
        .format(auxDate)
        .split(" ")[1],
      offset: todayTimezoneOffset,
    };

    // iterate trips and fill trips
    const trips = getters.trips;
    const details = getters.details;
    const boxes = getters.boxes;
    const descriptions = getters.descriptions;
    const currentUnit = getters.units;
    const selectedRateSpeed = getters.selectedRateSpeed;
    const event = rootGetters["eventShipping/currentEvent"];

    if (trips.constructor === Array && trips.length) {
      for (let step = 0; step < trips.length; step++) {
        const stage = {} as Stage;
        const trip: any = trips[step];
        const detail: any = details[step];
        stage.descriptions = [] as any[];

        stage.from = {} as any;
        stage.to = {} as any;

        if (selectedRateSpeed && selectedRateSpeed[step]) {
          // Send the ShipDate and the DeliverDate as the selected rate delivery date in the user's Timezone
          stage.shipDate = selectedRateSpeed[step].ship
            ? selectedRateSpeed[step].ship
            : ISOStringDate;
          stage.deliverDate = selectedRateSpeed[step].deliver
            ? selectedRateSpeed[step].deliver
            : ISOStringDate;
          stage.rate = selectedRateSpeed[step].rate
            ? selectedRateSpeed[step].rate
            : 0;
          stage.rateShortName = selectedRateSpeed[step].shortName
            ? selectedRateSpeed[step].shortName
            : "";
          stage.rateLongName = selectedRateSpeed[step].name
            ? selectedRateSpeed[step].name
            : "";
        } else {
          stage.shipDate = ISOStringDate; // selected rate ship
          stage.deliverDate = ISOStringDate; // selected rate delivery
          stage.rate = 0;
          stage.rateShortName = "";
          stage.rateLongName = "";
        }

        if (
          descriptions &&
          descriptions.length &&
          descriptions[step] &&
          descriptions[step].length
        ) {
          stage.descriptions = descriptions[step];
        }

        stage.pickupOption = getters.pickupOption[step]
          ? getters.pickupOption[step].entryId
          : 0;
        stage.stage = step;

        const fromComponents = this.mapAddressComponents(
          trip.from.address_components,
          trip.from.formatted_address
        ) as any;
        const fromState = this.getState(detail?.from, fromComponents);

        stage.from = {
          direction: "from",
          company: String(
            detail.from && detail.from.company
              ? detail.from.company
              : ("" as string)
          ).trim(),
          firstName: String(
            detail.from && detail.from.firstName
              ? detail.from.firstName
              : ("" as string)
          ).trim(),
          lastName: String(
            detail.from && detail.from.lastName
              ? detail.from.lastName
              : ("" as string)
          ).trim(),
          phoneNumber: String(
            detail.from && detail.from.phone
              ? detail.from.phone
              : ("" as string)
          ).trim(),
          address1: String(
            detail.from && detail.from.streetAddress
              ? detail.from.streetAddress
              : ((fromComponents.address1
                  ? fromComponents.address1
                  : "") as string)
          ).trim(),
          address2: String(
            detail.from && detail.from.address2
              ? detail.from.address2
              : ((fromComponents.address2
                  ? fromComponents.address2
                  : "") as string)
          ).trim(),
          address3: String(
            detail.from && detail.from.address3
              ? detail.from.address3
              : ((fromComponents.address3
                  ? fromComponents.address3
                  : "") as string)
          ).trim(),
          city: String(
            detail.from && detail.from.city
              ? detail.from.city
              : ((fromComponents.city ? fromComponents.city : "") as string)
          ).trim(),
          postalCode: String(
            detail.from && detail.from.postalCode
              ? detail.from.postalCode
              : ((fromComponents.postalCode
                  ? fromComponents.postalCode
                  : "") as string)
          ).trim(),
          state: String(fromState as string).trim(),
          country: String(
            detail.from && detail.from.country && detail.from.country.code
              ? detail.from.country.code
              : ((fromComponents.countryCode
                  ? fromComponents.countryCode
                  : fromComponents.state?.country
                  ? fromComponents.state.country
                  : "") as string)
          ).trim(),
          location: {
            lat: (typeof trip.from.geometry.location.lat === "function"
              ? trip.from.geometry.location.lat()
              : trip.from.geometry.location.lat) as Number, //
            lng: (typeof trip.from.geometry.location.lng === "function"
              ? trip.from.geometry.location.lng()
              : trip.from.geometry.location.lng) as Number, //
          },
          passedAvs: !!detail.from.passedAvs,
          isResidential: !!detail.from.isResidential,
        } as any;

        const toComponents = this.mapAddressComponents(
          trip.to.address_components,
          trip.to.formatted_address
        ) as any;
        const toState = this.getState(detail?.to, toComponents);
        stage.to = {
          direction: "to",
          company: String(
            detail.to && detail.to.company ? detail.to.company : ("" as string)
          ).trim(),
          firstName: String(
            detail.to && detail.to.firstName
              ? detail.to.firstName
              : ("" as string)
          ).trim(),
          lastName: String(
            detail.to && detail.to.lastName
              ? detail.to.lastName
              : ("" as string)
          ).trim(),
          phoneNumber: String(
            detail.to && detail.to.phone ? detail.to.phone : ("" as string)
          ).trim(),
          address1: String(
            detail.to && detail.to.streetAddress
              ? detail.to.streetAddress
              : ((toComponents.address1 ? toComponents.address1 : "") as string)
          ).trim(),
          address2: String(
            detail.to && detail.to.address2
              ? detail.to.address2
              : ((toComponents.address2 ? toComponents.address2 : "") as string)
          ).trim(),
          address3: String(
            detail.to && detail.to.address3
              ? detail.to.address3
              : ((toComponents.address3 ? toComponents.address3 : "") as string)
          ).trim(),
          city: String(
            detail.to && detail.to.city
              ? detail.to.city
              : ((toComponents.city ? toComponents.city : "") as string)
          ).trim(),
          postalCode: String(
            detail.to && detail.to.postalCode
              ? detail.to.postalCode
              : ((toComponents.postalCode
                  ? toComponents.postalCode
                  : "") as string)
          ).trim(),
          state: String(toState as string).trim(),
          country: String(
            detail.to && detail.to.country && detail.to.country.code
              ? detail.to.country.code
              : ((toComponents.countryCode
                  ? toComponents.countryCode
                  : toComponents.state?.country
                  ? toComponents.state.country
                  : "") as string)
          ).trim(),
          location: {
            lat: (typeof trip.to.geometry.location.lat === "function"
              ? trip.to.geometry.location.lat()
              : trip.to.geometry.location.lat) as Number, //
            lng: (typeof trip.to.geometry.location.lng === "function"
              ? trip.to.geometry.location.lng()
              : trip.to.geometry.location.lng) as Number, //
          },
          passedAvs: !!detail.to.passedAvs,
          isResidential: !!detail.to.isResidential,
        } as any;
        shipment.stages.push(stage);
      }
    }

    // iterate boxes and fill packages
    if (boxes.constructor === Array && boxes.length) {
      for (let step = 0; step < boxes.length; step++) {
        const _package = {} as Package;
        const box: Box = boxes[step];
        const description: any =
          descriptions && descriptions[0] && descriptions[0][step]
            ? descriptions[0][step]
            : { outsideDescription: "", insideDescription: "" };
        _package.name = String(box ? box.preset : "").trim();
        _package.length = box.length;
        _package.height = box.height;
        _package.width = box.width;
        _package.weight = box.weight;
        _package.linearUnit =
          currentUnit && currentUnit.length === "cm" ? "CM" : "IN";
        _package.weightUnit =
          currentUnit && currentUnit.weight === "kg" ? "KG" : "LB";
        _package.commodityCurrencyCode = box?.commodityCurrencyCode;
        _package.commodityDuty = box?.commodityDuty;
        _package.hsCode = box?.hsCode;
        _package.isCalculable = box?.isCalculable;
        _package.commodityId = box?.commodityId;
        _package.totalCommodityTaxesAndFee = box?.totalCommodityTaxesAndFee;
        _package.totalCommodityDutyAndTax = box?.totalCommodityDutyAndTax;
        _package.value =
          typeof box.marketValue === "string"
            ? Number(box.marketValue)
            : box.marketValue;
        _package.declaredValue = box.premiumProtection.coverage;
        _package.coverage = box.premiumProtection.coverage;
        _package.protectionFee = box.premiumProtection.cost;
        _package.contentOption = box.contents?.code as number;
        _package.outsideDescription = description.outsideDescription;
        _package.insideDescription = description.insideDescription;

        // BIK-515
        _package.hasOverMaxPackage = box?.hasOverMaxPackage;

        shipment.packages.push(_package);
      }
    }

    if (session) {
      // fill session
      const user = session.getUser();
      shipment.session.profile.email = user.email ? user.email : "";
      shipment.session.profile.firstName = user.firstName ? user.firstName : "";
      shipment.session.profile.lastName = user.lastName ? user.lastName : "";
      shipment.session.profile.userId =
        user && user.getID ? user.getID() : user.id ? user.id : "";
    }

    if (event && event.couponCode) {
      shipment.session.eventCode = event.couponCode;
      commit("setEventCoupon", event.couponCode);
    }

    try {
      Sentry.addBreadcrumb({
        message: "Threshold: temp Order request.",
        data: { temporalOrderBodyObject: shipment },
        category: "getTemporalOrderBodyObject",
      });
    } catch (e) {
      console.log(e);
    }
    return shipment;
  }

  public static getState(detail: Details, components: any): string {
    if (typeof detail?.state === "string") {
      return detail.state;
    } else if (detail?.state?.id) {
      return detail.state.id;
    } else if (components?.state?.stateprovcode) {
      return components.state.stateprovcode;
    } else {
      return "";
    }
  }

  public static getAddressRequestParams(
    addressComponents,
    forLocation = false,
    type,
    originalAddress,
    company?
  ) {
    const components =
      type !== 3
        ? this.mapAddressComponents(addressComponents, originalAddress)
        : addressComponents;
    forLocation = !(
      components.country &&
      components.postalCode &&
      components.city &&
      components.state
    );
    const withoutStreetAddress = !!(
      !components.streetAddress && !components.address2
    );
    if (forLocation) {
      const data: ForLocationI = {};
      if (components.city) {
        data.city = components.city ? components.city : "";
      }
      if (components.state) {
        data.stateCode = components.state ? components.state : "";
      }
      if (components.postalCode) {
        data.postalCode = components.postalCode ? components.postalCode : "";
      }
      if (components.country) {
        data.countryCode = components.countryCode ? components.countryCode : "";
      }
      data.provider = "UPS";
      const searchParams = new URLSearchParams(data as string);
      return { forLocation, value: searchParams.toString() };
    } else {
      const data: AddressRequest = new AddressRequest();
      if (components.address1 || components.streetAddress) {
        data.address1 = components.streetAddress
          ? components.streetAddress
          : "";
      }
      if (components.address2) {
        data.address2 = components.address2 ? components.address2 : "";
      }
      if (components.city) {
        data.city = components.city ? components.city : "";
      }
      if (components.state) {
        data.state = components.state ? components.state : "";
      }
      if (components.postalCode) {
        data.postalCode = components.postalCode ? components.postalCode : "";
      }
      if (components.country) {
        data.country = components.countryCode ? components.countryCode : "";
      }
      if (company && !originalAddress.includes(company)) {
        data.company = company;
      }
      data.triggerType = type || 3;
      const searchParams = new URLSearchParams(data as any);

      return {
        forLocation,
        withoutStreetAddress,
        value: searchParams.toString(),
      };
    }
  }

  public static mergeObjects(obj1, obj2) {
    return { ...obj1, ...obj2 };
  }

  public static roundToNearest(num: number) {
    const multiplier = Math.pow(10, 0);
    return Math.round(num * multiplier) / multiplier;
  }

  /**
   * Formats a given date string into `YYYY-MM-DD` format.
   *
   * @param {string} stringDate - The date string to be formatted.
   * @returns {string} The formatted date string in `YYYY-MM-DD` format.
   */
  public static getFormattedDate(stringDate: string): string {
    return this.dateToISO(new Date(stringDate)).split("T")[0];
  }

  /**
   * Converts a given date to an ISO string, adjusting for the local timezone offset.
   *
   * @param {Date} date - The date object in UTC to be converted.
   * @returns {string} The ISO string representation of the date, adjusted for the local timezone.
   */
  public static dateToISO(date: Date): string {
    return new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    ).toISOString();
  }

  public static getCreateOrderStripeBodyObject(getters: any, paymentData) {
    try {
      const body = {} as any;
      body.nonce = paymentData.nonce;
      body.storeCode = paymentData.storeCode;
      body.purchaseOrder = paymentData.purchaseOrder
        ? paymentData.purchaseOrder
        : getters.PO
        ? getters.PO
        : "";
      body.savePaymentMethod = paymentData.savePaymentMethod;
      body.donation = paymentData.donation;
      body.total = paymentData.total;
      const discount = getters.discount;
      const promo = getters.promoDiscount;
      body.discountAmount =
        getters.discountTotal > getters.subTotalBeforeDiscount
          ? getters.subTotalBeforeDiscount
          : getters.discountTotal;
      body.usedBalance = getters.customerBalanceApplied
        ? parseFloat(Number(getters.customerBalanceApplied * 100).toFixed(0))
        : 0;
      body.fees = [] as any;

      // iterate trips and fill trips
      const rates = paymentData.rates;
      const selectedRateSpeed = paymentData.selectedRateSpeed;
      const selectedRateSpeedIndex = paymentData.selectedRateSpeedIndex;
      const premiumProtection = paymentData.premiumProtection;
      const fees = paymentData.fees;

      if (discount) {
        body.StripeDiscount = {
          id: discount?.id,
        };
      }

      if (promo) {
        body.StripePromoCode = {
          id: promo?.id,
        };
      }

      if (rates?.[0]) {
        for (let step = 0; step < rates[0].length; step++) {
          let clearanceFee = 0;
          if (
            selectedRateSpeedIndex &&
            fees &&
            fees[step]?.landedCost &&
            fees[step].landedCost.length &&
            fees[step].landedCost[selectedRateSpeedIndex[step]] &&
            fees[step].landedCost[selectedRateSpeedIndex[step]].clearanceFee
          ) {
            clearanceFee =
              fees[step].landedCost[selectedRateSpeedIndex[step]].clearanceFee;
          }

          const fee = {
            stage: fees?.[step]?.stage !== undefined ? step : 0,
            baseFee: selectedRateSpeed?.[step]?.rate ?? 0,
            customsFee: clearanceFee,
            protectionFee: premiumProtection?.cost ?? 0,
            pickupFee:
              rates[0][step]?.pickupFee !== undefined
                ? rates[0][step].pickupFee
                : 0,
            residentialFee: fees?.[step]?.residentialFee ?? 0,
            hazMatFee: 0, // always 0
          };
          body.fees.push(fee);
        }
      }
      Sentry.addBreadcrumb({
        message: "Threshold: payment Order request.",
        data: { savePaidOrderBodyObject: body },
        category: "getCreateOrderStripeBodyObject",
      });
      return body;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  public static getSaveSearchBodyObject(
    search: Search | any,
    ownerID = ""
  ): string {
    const jsonObject = {} as any;
    const auxDate = new Date();
    let firstStage, lastStage;

    auxDate.setTime(search?.created);
    jsonObject.searchDate = auxDate.toISOString();
    if (search.savedSearchId) {
      jsonObject.savedSearchId = search.savedSearchId;
    }
    if (search.savedSearchCode) {
      jsonObject.savedSearchCode = search.savedSearchCode;
    }

    if (search && search.trips && search.trips.length >= 1) {
      firstStage = this.mapAddressComponents(search.trips.slice(0, 1)[0]?.from);
      lastStage = this.mapAddressComponents(search.trips.slice(-1)[0]?.to);
    }

    // --trash data--
    jsonObject.fromPostalCode = firstStage?.postalCode ?? "a";
    jsonObject.fromCity = firstStage?.city ?? "b";
    jsonObject.fromState = firstStage?.stateName ?? "c";
    jsonObject.fromCountry = firstStage?.country ?? "d";

    jsonObject.toPostalCode = lastStage?.postalCode ?? "e";
    jsonObject.toCity = lastStage?.city ?? "f";
    jsonObject.toState = lastStage?.stateName ?? "g";
    jsonObject.toCountry = lastStage?.country ?? "h";

    // --end of trash--
    jsonObject.serializedSearchData = JSON.stringify(search);
    jsonObject.userId = ownerID;
    jsonObject.deleted = false;
    return JSON.stringify(jsonObject);
  }
}
