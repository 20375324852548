import https from "https";

export default function ({ app, store, $axios }: any) {
  // Set up the HTTPS agent to ignore certificate errors
  $axios.defaults.httpsAgent = new https.Agent({ rejectUnauthorized: false });
  // Error handling for Axios requests
  $axios.onError((error: any) => {
    if (error.response) {
      // Get the HTTP status code
      const status = error.response.status;
      // Handle 401 Unauthorized errors
      if (status === 401) {
        console.log("401 error - Unauthorized");
        if (app.$auth.user) {
          store.dispatch("user/logoutUser");
          window.location.reload();
        }
        // Prevent the error from being sent to Sentry
        return Promise.resolve();
      }
      // You can add handling for other status codes here (e.g., 404 Not Found, 500 Internal Server Error, etc.)
      // ...
      // For all other errors, you can choose to use nuxtError to handle it globally or just log it
    }
    // Optionally, log the error for debugging purposes
    console.error("Axios error:", error);
    // Return a rejected promise to handle the error properly in the calling code
    return Promise.reject(error);
  });
}
