import { render, staticRenderFns } from "./default.vue?vue&type=template&id=c898cb8c&"
var script = {}
import style0 from "./default.vue?vue&type=style&index=0&id=c898cb8c&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopContent: require('/opt/atlassian/pipelines/agent/build/components/TopContent.vue').default,Navbar: require('/opt/atlassian/pipelines/agent/build/components/Navbar.vue').default,CookieBanner: require('/opt/atlassian/pipelines/agent/build/components/CookieBanner.vue').default,Footer: require('/opt/atlassian/pipelines/agent/build/components/Footer.vue').default})
